/* You can add global styles to this file, and also import other style files */

@import "~@ng-select/ng-select/themes/default.theme.css";

@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap');
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");
@import "./assets/deux-metronic-mods.scss";
@import "./assets/metronic-demo3/sass/style.angular.scss";
@import "./assets/metronic-demo3/plugins/keenthemes-icons/font/ki.css";

@import "../node_modules/angular-calendar/css/angular-calendar.css";

// TODO don't know why these didn't work in çomponent scss
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background-color: #ebf5ff !important;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #d1e5ff !important;
}

html, body {
  height: 100%;
}

.nav-link.active {
  font-weight: bold;
}

[role="main"] {
  padding-top: 48px; /* Space for fixed navbar */
}

.ngb-highlight {
  background-color: yellow;
}

.bg-purple {
  background: rgb(48,0,50);
}
.bg-lightyellow {
  background: lightyellow;
}
.bg-gray {
  background: rgb(74,74,74);
}
.bg-blue {
  background: rgb(50,101,196);
}
.bg-red {
  background: rgb(196,50,53);
}


td.price,
td.commission,
td.capacity,
td.deux-number {
  text-align: center
}

th[sortable] {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}

// \00A0 is &nbsp;

th[sortable].desc:after {
  content: "\00A0\2193";
}

th[sortable].asc:after {
  content: "\00A0\2191";
}

.deux-chevron-left {
  border-style: solid;
  border-width: .2em .2em 0 0;
  display: inline-block;
  width: .75em;
  height: .75em;
  margin-left: .25em;
  margin-right: .15em;
  transform: rotate(-135deg);
}

.deux-chevron-right {
  border-style: solid;
  border-width: .2em .2em 0 0;
  display: inline-block;
  width: .75em;
  height: .75em;
  // margin-left: .25em;
  // margin-right: .15em;
  // transform: rotate(-135deg);
  transform: rotate(45deg);
}

a {
  cursor: pointer;
}

.merchant-logo-wrapper {
  width: 100%;
  height: 100px;
  overflow: hidden;
}

.merchant-logo-img {
  display: block;
  height: 100%;
  margin-left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}
